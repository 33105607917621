@include critical {

	.c-breadcrumbs__list {
		color: $cBreadcrumbsInnerContentListColor;
		font-size: rem($fontSize - 2);
		line-height: rem($fontHeight - 2);
		list-style: none;
		margin: 0;
		padding: 0 0 20px 0;
		overflow: hidden;
		max-width: none;
		width: 100%;
		white-space: nowrap;
		overflow-x: auto;
	}

	.c-breadcrumbs__list::-webkit-scrollbar {
		height: 20px;
	}

	.c-breadcrumbs__list::-webkit-scrollbar-track {
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAFCAIAAAAL5hHIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDkuMC1jMDAwIDc5LjE3MWMyN2ZhYiwgMjAyMi8wOC8xNi0yMjozNTo0MSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDI0LjEgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjNEMEUxNUIzOTY1ODExRURBOENDRUFCNTRBNDgwN0RBIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjNEMEUxNUI0OTY1ODExRURBOENDRUFCNTRBNDgwN0RBIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6M0QwRTE1QjE5NjU4MTFFREE4Q0NFQUI1NEE0ODA3REEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6M0QwRTE1QjI5NjU4MTFFREE4Q0NFQUI1NEE0ODA3REEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4cmrVkAAAAFElEQVR42mK4f/8+EwMDAzIGCDAAL24CpqitpN8AAAAASUVORK5CYII=");
		background-position: 0 0;
		background-repeat: repeat-x;
	}

	.c-breadcrumbs__list::-webkit-scrollbar-thumb {
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAFCAIAAAAL5hHIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDkuMC1jMDAwIDc5LjE3MWMyN2ZhYiwgMjAyMi8wOC8xNi0yMjozNTo0MSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDI0LjEgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkRBMTNERDE3OTY1NzExRUQ5OEVBRDYzQTNFN0RDNEQ5IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkRBMTNERDE4OTY1NzExRUQ5OEVBRDYzQTNFN0RDNEQ5Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6REExM0REMTU5NjU3MTFFRDk4RUFENjNBM0U3REM0RDkiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6REExM0REMTY5NjU3MTFFRDk4RUFENjNBM0U3REM0RDkiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6crqD1AAAAFElEQVR42mJwDwpnYmBgQMYAAQYAETQA+fL2MQcAAAAASUVORK5CYII=");
		background-position: 0 0;
		background-repeat: repeat-x;
	}

	.c-breadcrumbs__list-item {
		display: inline;
		list-style: none;
		margin: 0;
		padding: 0;
		font-size: rem(14px);

		&:after {
			color: $cBreadcrumbsInnerContentListLevel1BeforeColor;
			content: "/";
			display: inline;
			margin: 0;
			padding: 0 6px;
		}

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}

		&.display {
			display: inline;
		}
	}

	.c-breadcrumbs__link {
		color: $cBreadcrumbsAIntroCategoryColor;
		text-decoration: underline;

		&:hover,
		&:focus {
			color: $cBreadcrumbsAIntroCategoryHoverColor;
			text-decoration: none;
		}
	}
	// Mobile tweaks
	@include mq-max($bpDesktop - 1) {
		.c-breadcrumbs--hide-home-mobile .c-breadcrumbs__list-item.is-home,
		.c-breadcrumbs--hide-mobile {
			display: none;
		}
	}
}


// Breadcrumb adjustments when used in certain containers

@include critical {
	.lContentInner .c-breadcrumbs__container {
		margin-top: 5px;
	}
}

.c-section {

	.c-breadcrumbs__link {

		@include critical {
			color: $cBreadcrumbsAIntroCategoryColor;
		}

		&:hover,
		&:focus {
			@include defer {
				color: $cBreadcrumbsAIntroCategoryHoverColor;
			}
		}
	}

}

.c-section--intro-alt {

	.c-breadcrumbs {

		@include critical {
			.c-breadcrumbs__list {
				color: $cBreadcrumbsListIntroAltColor;

				.c-breadcrumbs__list-item:before {
					color: $cBreadcrumbsListLevel1BeforeIntroAltColor;
				}
			}
		}

		.c-breadcrumbs__link {

			@include critical {
				color: $cBreadcrumbsAIntroAltColor;
			}

			&:hover,
			&:focus {

				@include defer {
					color: $cBreadcrumbsAIntroAltHoverColor;
				}
			}
		}
	}
}

@include mq-min($bpDesktop) {

	@include critical {
		.lCols .c-breadcrumbs__container {
			margin-top: 15px;
		}
	}

	.c-section--category {

		.c-breadcrumbs {

			@include critical {
				padding: 0 0 30px 0;
			}
		}
	}
}

@include critical {
	.c-section--breadcrumbs-only .c-breadcrumbs__list {
		padding: 20px;

		@include mq-min($bpExMega + 50px) {
			padding: 20px 0;
		}
	}
}
