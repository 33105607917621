﻿@mixin articleCardSmallAlignment() {

	.c-article-card__footer {
		position: absolute;
		bottom: 20px;
		left: 20px;
		right: 20px;

		@include mq-min($bpTabletMassive) {
			bottom: 25px;
			left: 25px;
			right: 25px;
		}
	}

	.c-article-card__summary {
		padding-bottom: rem(100px);
		margin-bottom: 0;
	}
}

@include critical {

	.c-article-list__list {
		padding: 0;
		margin: 0;
		list-style: none;

		@include mq-min($bpTablet) {
			display: flex;
			flex-wrap: wrap;
			margin-left: -25px;
		}
	}

	.c-article-list__list-item {
		margin: 0 0 20px 0;
		padding: 0;
		width: 100%;

		@include mq-min($bpTablet) {
			margin: 0 0 25px 25px;
		}
	}

	.c-article__content .c-article-list__list-item {
		padding: 0 !important;

		&:before {
			display: none !important;
		}
	}

	.c-article-list__list-item:not(.c-article-list__list-item--large) {
		@include articleCardSmallAlignment();
	}

	.c-article-list__list-item--large {

		@include mq-max($bpTabletMassive - 1) {
			@include articleCardSmallAlignment();
		}
	}

	.c-article-list__list-item--full {
		width: 100% !important;
	}

	.c-article-list__list-item--popular {
		padding-bottom: 5px;
		margin-bottom: 25px;
		border-bottom: 1px solid $cArticleListSeperatorBorderColor;
		display: none;

		@include mq-min($bpTablet) {
			display: block;
			padding-bottom: 30px;
			margin-bottom: 50px;
		}
	}

	.c-article-list__list--3-col,
	.c-article-list__list--4-col {
		@include mq-min($bpTabletLarge) {
			.c-article__content & {
				margin-left: -25px;
			}
		}
	}

	.c-article-list__list--3-col .c-article-list__list-item {
		@include mq-min($bpTabletLarge) {
			width: calc(50% - 25px);
		}

		@include mq-min($bpLarge) {
			width: calc(33.33% - 25px);
		}
	}

	.c-article-list__list--4-col .c-article-list__list-item {
		@include mq-min($bpTabletLarge) {
			width: calc(50% - 25px);
		}

		@include mq-min($bpLarge) {
			width: calc(33.33% - 25px);
		}

		@include mq-min($bpMassive) {
			width: calc(25% - 25px);
		}
	}

	.c-article-list__title {
		position: relative;
		font-size: rem(21px);
		font-weight: 600;
		position: relative;
		text-align: center;
		margin: 0 0 25px 0;
		padding: 0 0 25px 0;
		border-bottom: 1px solid $cArticleListSeperatorBorderColor;

		@include mq-min($bpTablet) {
			margin: 25px 0 50px 0;
			padding: 0;
			font-size: rem(28px);
			border-bottom: none;

			.c-article__content & {
				margin-bottom: 25px;
			}

			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				width: 100%;
				border-top: 1px solid $cArticleListSeperatorBorderColor;
			}
		}
	}

	.c-article-list__title-inner {
		@include mq-min($bpTablet) {
			position: relative;
			z-index: 100;
			display: inline-block;
			background-color: $cSectionAltBackgroundColor;
			padding: 0 50px;

			.c-section--splash & {
				background-color: $cSectionSplashBackgroundColor;
			}

			.c-article__content & {
				background-color: #FFF;
			}
		}
	}
}

.c-article-list__title--tight-top {

	@include critical {
		margin-top: 0;
	}
}

@include defer {
	.c-article-list__show-more {
		margin: 30px 30px 0 30px;
		text-align: center;

		@include mq-min($bpTabletLarge) {
			margin: 30px 40px 0 40px;
		}
	}

	.c-article-list__show-more-button {
		padding: 0 20px;
		display: inline-flex;
		align-items: center;

		@include mq-min($bpTabletLarge) {
			padding: 0 50px;
		}
	}

	.c-article-list__show-more-button__additional {

		@include mq-max($bpMobileMid - 1) {
			display: none;
		}
	}

	.c-article-list__show-more-icon {
		color: $colorWhite;
		width: 25px;
		height: 25px;
	}

	.c-article-list__show-more-container {
		overflow-anchor: none;
	}
}
