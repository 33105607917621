.c-alert-message {

	@include critical {
		padding: rem(20px);
		color: $cAlertMessageFontColor;
		background-color: $cAlertMessageBackgroundColor;
		margin-bottom: rem(20px);
		font-size: 1rem;
		box-sizing: border-box;

		p {
			margin-bottom: 0;
			padding: 0;
		}

		a {
			color: $colorWhite;
			text-decoration: underline;
		}

		a:hover,
		a:focus {
			text-decoration: none;
		}
	}
}


.c-alert-message--error {
	@include critical {
		color: $cAlertMessageErrorFontColor;
		background-color: $cAlertMessageErrorBackgroundColor;
	}
}

.c-alert-message--info {
	@include critical {
		color: $cAlertMessageInfoFontColor;
		background-color: $cAlertMessageInfoBackgroundColor;
	}

	a {
		color: $cAlertMessageInfoFontColor;
	}
}

.c-alert-message--toast-container {
	@include critical {
		position: fixed;
		bottom: rem(20px);
		right: rem(20px);
		left: rem(20px);
		max-width: $bpExMega;
		margin: auto;
		z-index: 2147483640;

		.c-alert-message {
			margin: 0;
			padding-right: 50px;
		}
	}
}

.c-alert-message__close {
	@include critical {
		position: absolute;
		top: 50%;
		right: 20px;
		background-color: transparent;
		border: none;
		padding: 0;
		margin-top: -12px;
		color: $colorWhite;
		cursor: pointer;
	}
}
