.c-carousel__inner {

	@include critical {
		overflow-x: hidden;
	}
}

.c-carousel__list {

	@include critical {
		display: flex;
		flex-direction: row;
		padding: 0;
		list-style: none;
		@include clearfix();

		&.splide__list {
			align-items: normal;
		}

		.no-js & {
			margin: 0 -10px;
		}
	}
}

.c-carousel__item {

	@include critical {
		margin: 0;
		padding: 0 10px;
		@include box-sizing();

		.c-carousel__list--initialised & {
			display: block !important;
		}
	}
}

.c-carousel__button {

	@include critical {
		display: none;
	}

	@include defer {
		display: block;
		overflow: visible;
		cursor: pointer;
		&[disabled] {
			display: none !important;
		}
	}
}

.c-carousel--faded-edges,
.c-carousel--scroll {

	@include critical {

		.c-carousel__button {
			display: none;
		}
	}

	@include defer {
		position: relative;

		.c-carousel__button {
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
			margin: 0;
			padding: 0;
			border: 0;
			@include appearance-none();
			background: transparent;
		}

		.c-carousel__button svg {
			display: none;
		}

		.c-carousel__button:after {
			content: '';
			width: 40px;
			height: 40px;
			@include appearance-none();
			margin: 0;
			padding: 0;
			background-image: $cCarouselButtonBackgroundImage;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -20px 0 0 -45px;
			display: block;
			@include box-sizing();
			cursor: pointer;

			@include mq-min($bpMammoth) {
				margin-left: -65px;
			}
		}

		.c-carousel__button--left:after {
			transform: rotate(180deg);
			transform-origin: center center;
		}

		.c-carousel__button--right {
			right: 0;
			left: auto;

			&:after {
				left: auto;
				right: 50%;
				margin: -20px -45px 0 0;

				@include mq-min($bpMammoth) {
					margin-right: -65px;
				}
			}
		}
	}
}

.c-carousel--faded-edges {

	@include defer {
		.c-carousel__button {
			width: calc(25% - 5px);
			background: linear-gradient(to left, rgba(255,255,255,0) 0%, $cCarouselButtonGradientColor 100%);

			@include mq-min(640px) {
				width: 90px;
			}

			@include mq-min(1225px) {
				width: calc(9.5% - 20px);
			}
		}

		.c-carousel__button--right {
			background: linear-gradient(to right, rgba(255,255,255,0) 0%, $cCarouselButtonGradientColor 100%);
		}

		&.c-carousel--on-white {

			.c-carousel__button {
				background: linear-gradient(to left, rgba(255,255,255,0) 0%, $cCarouselButtonOnWhiteGradientColor 100%);
			}

			.c-carousel__button--right {
				background: linear-gradient(to right, rgba(255,255,255,0) 0%, $cCarouselButtonOnWhiteGradientColor 100%);
			}
		}
	}
}

.c-carousel--scroll {
	@include defer {
		.c-carousel__button--left,
		.c-carousel__button--right {
			display: none;
		}

		@media (hover: hover) {
			.c-carousel__button--left {
				@include mq-min($bpTabletLarge) {
					display: block;
					margin-top: -20px;
					left: 35px;
				}

				@include mq-min($bpDesktop) {
					left: 30px;
				}

				@include mq-min($bpMammoth) {
					left: 45px;
				}
			}

			.c-carousel__button--right {
				@include mq-min($bpTabletLarge) {
					display: block;
					margin-top: -20px;
					right: 35px;
				}

				@include mq-min($bpDesktop) {
					right: 30px;
				}

				@include mq-min($bpMammoth) {
					right: 45px;
				}
			}
		}
	}
}

.c-carousel--inside-grid {

	@include defer {

		.c-carousel__button {
			width: calc(25% - 5px);

			@include mq-min(640px) {
				width: calc(16.666666667% - 20px);
			}

			@include mq-min(1225px) {
				width: calc(12.5% - 20px);
			}
		}
	}
}

.c-carousel--inside-product-column,
.c-carousel--inside-column {

	&.splide {

		@include critical {
			visibility: visible;

			@include mq-min(989px) {
				margin-left: -10px;
			}
		}
	}

	@include defer {
		position: relative;

		@include mq-min(989px) {

			&.c-carousel--inside-product-column--basket {
				margin-left: 0;
			}
		}

		.c-carousel__inner.is-loading {
			filter: blur(3px);
			pointer-events: none;
		}

		.c-carousel__button {
			width: calc(25% - 5px);

			@include mq-min(640px) {
				width: calc(16.666666667% - 20px);
			}

			@include mq-min(1225px) {
				width: calc(12.5% - 20px);
			}
		}
	}

	.c-carousel__item {

		@include critical {
			width: 50%;

			&:nth-child(n+3) { // all but the first 2
				@include mq-max(639px) {
					display: none;
				}
			}

			@include mq-min(640px) {
				width: 33.333333333%;

				&:nth-child(n+4) { // all but the first 3
					@include mq-max(1224px) {
						display: none;
					}
				}
			}

			@include mq-min(1225px) {
				width: 25%;

				&:nth-child(n+5) { // all but the first 4
					display: none;
				}
			}
		}
	}
}

.c-carousel--inside-product-column--basket.is-active {
	@include critical {
		.splide__list { // the React version of splide doesn't add the transition
			transition: transform 600ms cubic-bezier(.42,.65,.27,.99);
		}
	}
}

.c-carousel--free-scroll {

	&.c-carousel--inside-product-column {

		@include critical {

			@include mq-max($bpDesktop - 1) {
				margin: 0 -20px;
			}
		}
	}

	.c-carousel__inner {

		@include critical {
			overflow-x: scroll;
			overflow-y: hidden;
			width: 100%;
			-webkit-overflow-scrolling: touch;
		}
	}

	.c-carousel__list {

		@include critical {
			width: 9999px;
			padding: 0 10px;
		}
	}

	.c-carousel__item {

		@include critical {
			width: 220px;
			min-width: 220px;

			@include mq-min(1225px) {
				width: 250px;
				min-width: 250px;
			}
		}
	}
}

.c-carousel--on-white {
	.c-carousel__button {
		@include defer {
			background: transparent;
		}
	}
}

.c-carousel__top-sellers-wrapper {

	@include critical {
		margin-top: -8px;  /* Size of box shadow */
		@include mq-min(990px) {
			margin: 0;
			padding: 0 34px;
		}

		@include mq-min($bpMassive + 70) {
			margin: 0 -10px;
			padding: 0;
		}

		.c-carousel__item {
			padding-top: 8px; /* Size of box shadow */
			padding-bottom: 8px; /* Size of box shadow */
		}
	}
}

.c-carousel--single {

	@include critical {
		padding: 0;
		visibility: visible !important;
		.c-carousel__list {
			display: block;
			width: auto;
		}
	}
}

.c-carousel--with-pagination {
	@include critical {
		padding: 0 0 36px 0;
		opacity: 0;
		// arbitary heights to try and stop the page jumping
		.js & {
			height: 600px;

			@include mq-min($bpDesktop) {
				height: 800px;
			}
		}
	}

	@include defer {
		opacity: 1;
	}

	.c-carousel__item {
		@include critical {
			padding: 0;
		}
	}

	.c-carousel__button {

		@include critical {
			display: none;
		}

		@include defer {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 50%;
			left: 0;
			height: 80px;
			width: 40px;
			background: #f8f8f8;
			margin-top: -58px;
			z-index: 99;
			border-top-right-radius: 190px;
			border-bottom-right-radius: 190px;
			fill: $cCarouselPagerLinkColor;
			transition: opacity 0.25s ease, color 0.25s ease;
			opacity: 0;
			border: none;

			.matchmedia-no-hover & {
				opacity: 1;
			}

			@include mq-max($bpDesktop) {
				.touch & {
					opacity: 1;
				}
			}

			.js-focus-visible &:focus:not(.focus-visible) {
				outline: none;
			}

			&:hover,
			.js-focus-visible &.focus-visible {
				fill: $cCarouselPagerLinkHoverColor;
				opacity: 1 !important;
			}
		}
	}

	.c-carousel__button--left svg {
		@include defer {
			transform-origin: 50% 50%;
			transform: rotate(180deg);
			margin-left: -8px;
		}
	}

	.c-carousel__button--right svg {
		@include defer {
			margin-left: 8px;
		}
	}

	.c-carousel__button--right {

		@include defer {
			left: auto;
			right: 0;
			border-radius: 0;
			border-top-left-radius: 190px;
			border-bottom-left-radius: 190px;
		}
	}

	html:not(.matchmedia-no-hover) & {

		&:hover {

			.c-carousel__button {

				@include defer {
					opacity: 0.7;
				}
			}
		}
	}
}

.c-carousel__pagination {

	@include critical {
		display: none;
	}

	@include defer {
		display: block;
		font-size: 0;
		line-height: 0;
		text-align: center;
		padding: 0 0 7px 0;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 99;
	}
}

.c-carousel__pagination li {

	@include defer {
		display: inline-block;
		padding: 0;
	}
}

.c-carousel__pagination-page {

	@include defer {
		display: block;
		width: 22px;
		height: 22px;
		position: relative;
		background-color: transparent;
		border: none;
		cursor: pointer;
		outline-offset: 2px;

		&:before {
			content: '';
			display: block;
			width: 10px;
			height: 10px;
			border: 2px solid $cCarouselPagerItemColor;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -7px 0 0 -7px;
			border-radius: 100%;
			background: none;
			transition: background 0.25s ease;
		}

		&.is-active:before {
			background: $cCarouselPagerItemColor;
		}
	}
}

.c-carousel--atb {

	@include defer {
		.js & {
			position: relative;
			height: auto;
		}

		.c-carousel__pagination {
			padding: 0;
			margin: 0;
			bottom: 0;
		}

		.c-carousel__item {
			border: 1px solid $colorWhite; // stop other images peeking through
		}

		.c-carousel__button {
			opacity: 0.7;

			svg {
				width: 25px;
				height: 25px;
			}
		}

		.c-carousel__button--left svg {
			margin-left: -20px;
		}

		.c-carousel__button--right svg {
			margin-right: -20px;
		}

		.c-carousel__pagination-page {
			width: 10px;
			height: 10px;
			display: inline-block;
			padding: 0;
			margin: 0 5px 3px 5px;
			background: $mAtbDetailItemPagerLinkBackroundColor;
			border-radius: 100%;
			text-align: left;
			text-indent: -9000px;
			border: none;
			cursor: pointer;

			&:before {
				display: none;
			}

			&:not(.focus-visible):focus {
				outline: none;
			}

			&.is-active {
				background: $mAtbDetailItemPagerLinkActiveBackroundColor;
			}

			&:not(:first-child) {
				margin-left: 10px;
			}
		}

		.c-image-zoom__button {
			display: block;
			opacity: 0.7;
			top: 10px;

			&:hover,
			&:focus {
				color: $cCarouselPagerLinkHoverColor;
				opacity: 1;
			}
		}

		.c-image-zoom__text {
			opacity: 0.0001;
		}
	}
}

.c-carousel--product-page-full-width {
	@include defer {
		width: calc(100% + 8px);
		margin-left: -8px;
		margin-right: -8px;
	}
}
